/* eslint-disable camelcase*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tab } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import { Layout, CustomTabs, CardWrapper, Heading } from './styles';
import {
  Journeys, AdventureCategories, VideosLibrary, VideoLibraryDashboard, Article,
  VideoArticle, EditWellnessJourney, AddWellnessJourney, AdminRoute, ArticleQuiz, AddQuizQuestion, ShowAllQuiz, Recipes, RecipeDetails, CreateRecipe, EditRecipe, RecipeCategory, AddFitnessVideo, AddCategory
} from '../../components';
import { getWebinarVideosCategory, getSelectedWebinarVideosCategory, getVideosCategory, getSelectedVideosCategory, getSelectedRecipeCategories, getAdventureCategories, getSelectedAdventureCategories, getFeaturedJourney, getRecommendedVideos, updateUserGlobalPoints, getUserRole, getRecipeCategories, getRecommendedRecipeDetails, getThemes, getUserProfile, getFavoriteArticles, getFavoriteVideos, getFavoriteRecipes, likeUnlikeEducationPost, getFeaturedVideos, getRecommendedArticle, getFeaturedRecipes } from '../../redux/actions';
import EditQuizQuestion from '../../components/EditQuizQuestion';
import EditFitnessVideo from '../../components/EditFitnessVideo';
import { getPermissionStatus } from '../../utils/methods';
import DailyChecklist from '../../components/DailyChecklistNewWellness';
import { withTranslation } from 'react-i18next';
import RecommendedVideos from '../../components/RecommendedVideos/recommendedVideo';
import ArticleCard from '../../components/AdventureCategories/recommendedArticles';
import RecommendedRecipe from '../../components/Recipes/recommendedRecipe';
import { toast } from 'react-toastify';
import SkeletonLoder from '../../components/common/skeletonLoder';

class Education extends Component {
  constructor() {
    super();
    this.state = {
      eventKey: 1,
      selectedItem: '',
      categoryKey: '',
      onHover: -1,
      tab: 1,
      save: 0,
      loderSidetabContentArray : [],
      activeTabClass: ''
    }
  }

  componentDidMount() {
    const newArray = new Array(10).fill(null).map((_, index) => `Item ${index + 1}`);
    this.setState({ loderSidetabContentArray: newArray });
    const { history, fetchAdventureCategories, fetchSelectedAdventureCategories, fetchFeaturedJourney, fetchVideosCategory, fetchSelectedVideosCategory, fetchRecommendedVideos,
      featuredJourney, /*featuredVideos,*/ fetchUserRole, fetchSelectedWebinarVideosCategory, getRecipeCategories, fetchSelectedRecipeCategories, getRecommendedRecipeDetails, fetchThemes, /*companyDetails,*/ getFavoriteArticles, getFavoriteRecipes, getFavoriteVideos, fetchFeaturedVideos, /*featureVideos,*/ fetchRecommendedArticles, recommendedArticle, getFeaturedRecipes } = this.props;
    fetchAdventureCategories();
    fetchThemes();
    fetchSelectedAdventureCategories();
    // fetchVideosCategory();
    // fetchSelectedVideosCategory();
    fetchSelectedWebinarVideosCategory();
    // fetchWebinarVideosCategory();
    // fetchSelectedRecipeCategories();
    fetchUserRole();
    // getRecipeCategories();
    // getRecommendedRecipeDetails();
    getFavoriteArticles(10);
    // getFeaturedRecipes();
    if (!featuredJourney) fetchFeaturedJourney();
    // if (!featuredVideos) fetchRecommendedVideos();
    // if (!featureVideos) fetchFeaturedVideos();
    if (!recommendedArticle) fetchRecommendedArticles();
    if (history.location.pathname.includes('videos-library')) {
      this.setState({
        eventKey: 2,
        tab: 2
      }, () => {
        getFavoriteVideos(10);
        fetchRecommendedVideos();
        fetchFeaturedVideos();
        fetchVideosCategory();
        fetchSelectedVideosCategory();
      });
    }
    // if (history.location.pathname.includes('webinar')) {
    //   this.setState({
    //     eventKey: 4
    //   })
    // } 
    else if (history.location.pathname.includes('recipes')){
      this.setState({
        eventKey: 3,
        tab: 3
      }, () => {
        getRecommendedRecipeDetails()
        getFavoriteRecipes(10);
        getFeaturedRecipes();
        getRecipeCategories();
        fetchSelectedRecipeCategories()
      })
    } 
    else {
      this.setState({
        eventKey: 1,
        tab: 1
      })
    }

    if(history.location.state && history.location.state.categoryKey){
      this.setState({
        eventKey: history.location.state.categoryKey === "ARTICLE" ? 1:history.location.state.categoryKey === "FITNESS" ? 2:history.location.state.categoryKey === "RECIPE" ? 3 : 1,
        categoryKey: history.location.state.categoryKey,
        tab: history.location.state.categoryKey === "ARTICLE" ? 1:history.location.state.categoryKey === "FITNESS" ? 2:history.location.state.categoryKey === "RECIPE" ? 3 : 1,
      })

    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { /*getFavoriteArticles, getFavoriteRecipes, getFavoriteVideos,*/ history } = this.props;
    if (prevProps.history.location.pathname.includes('adventure') && (prevState.eventKey === 2 || prevState.eventKey === 3 || prevState.eventKey === 4)) {
      this.setState({
        eventKey: 1,
        categoryKey: "ARTICLE",
        tab: 1
      }, () => {
        // getFavoriteArticles(10);
      })
    }
    if ((prevProps.history.location.pathname.includes('videos-library') || prevProps.history.location.pathname.includes('create-video')) && (prevState.eventKey === 1 || prevState.eventKey === 3 || prevState.eventKey === 4)) {
      this.setState({
        eventKey: 2,
        categoryKey: "FITNESS",
        tab: 2
      }, () => {
        // getFavoriteVideos(10);
      })
    }
    if (prevProps.history.location.pathname.includes('recipes') && (prevProps.recommendedRecipes !== this.props.recommendedRecipes) && (prevState.eventKey === 1 || prevState.eventKey === 2 || prevState.eventKey === 4)) {
      this.setState({
        eventKey: 3,
        categoryKey: "RECIPE",
        tab: 3
      }, () => {
        // getFavoriteRecipes(10);
      })
    }

    if(history.location.state && history.location.state.categoryKey && (prevProps.history.location.state.categoryKey !=  history.location.state.categoryKey)){
      this.setState({
        eventKey: 2,
        categoryKey: history.location.state.categoryKey,
        tab: 2
      })

    }
  }

  onChangeCategory = (key) => {
    this.setState({
      categoryKey: key
    })
  };

  handleSelect = (key) => {
    const { history, fetchFeaturedVideos, fetchRecommendedVideos, getRecommendedRecipeDetails, getFeaturedRecipes, getFavoriteRecipes, getFavoriteVideos, fetchVideosCategory, fetchSelectedVideosCategory, getRecipeCategories, fetchSelectedRecipeCategories } = this.props;
    this.setState({
      eventKey: Number(key),
      tab: Number(key)
    });

    switch (key) {
    case "1":
      history.push('/education/adventure');
      this.onChangeCategory('ARTICLE');
    
      break;
    case "2":
      fetchRecommendedVideos();
      fetchFeaturedVideos();
      getFavoriteVideos(10);
      fetchVideosCategory();
      fetchSelectedVideosCategory();
      history.push('/education/videos-library');
      this.onChangeCategory('WEBINAR');
      break;
    case "3":
      getRecommendedRecipeDetails();
      getFeaturedRecipes();
      getFavoriteRecipes(10);
      getRecipeCategories();
      fetchSelectedRecipeCategories()
      history.push('/education/recipes');
      this.onChangeCategory('RECIPE');
      break;
    }
  };

  showArticle = (adventure) => {
    const { history } = this.props;
    if (adventure.lock_status === 1) {
      history.push(`/education/adventure/${adventure.category_name.toLowerCase()}/article/${adventure.id}`)
    }
    else {
      toast.info('This article is locked, please read the previous articles in the same category to unlock');
    }
  };

  onSelect = (value) => {
    this.setState({
      selectedItem: value
    });
  };

  renderSideBar = () => {
    const { themesHeader } = this.props;

    return (
      <DailyChecklist
        themesHeader={themesHeader}
      />
    )
  }

  hadleshowMonthlyGoals = () => {
    this.setState((prevState) => ({ showMonthlyGoalsStatus: !prevState.showMonthlyGoalsStatus }))
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }
  onClickFavoriteRibbon = (type, typeId, likeStatus, favoriteStatus, featureType = 1) => {
    const { likeUnlikeEducationPost } = this.props;
    let obj =
    {
      "type": type,
      "type_id": typeId,
      "like_status": likeStatus,
    }
    if (featureType === 0) {
      obj['favorite_status'] = favoriteStatus;
    }
    likeUnlikeEducationPost(obj);
  }

  render() {
    const { eventKey, tab } = this.state;
    const { adventureCategories, networkError, featuredJourney, history, videoLibraries, selectedvideoLibraries, featuredVideos, updateUserGlobalPoints,
      role, location, recipeDetailsById, recipeCategories, recommendedRecipes, userPermissions, selectedAdventureCategories, themes, link, favoriteRecipes, favoriteVideos, favoriteArticles, featureVideos, recommendedArticle, featuredRecipes, fitnessVideo, t, themesHeaderAll } = this.props;
    if ((_.isUndefined(adventureCategories) || _.isNull(adventureCategories) || _.isUndefined(featuredJourney) || _.isNull(featuredJourney) || 
    /*_.isUndefined(videoLibraries) || _.isNull(videoLibraries) || _.isUndefined(featuredVideos) || _.isNull(featuredVideos) || _.isNull(webinarLibraries) || !recipeCategories ||*/ !role || _.isUndefined(recommendedArticle) || _.isNull(recommendedArticle) /*|| _.isUndefined(featureVideos) || _.isNull(featureVideos) || _.isUndefined(featuredRecipes) || _.isNull(featuredRecipes) */) && !networkError) {
      return <div style={{width:'1246px', margin:'auto'}}>
        <SkeletonLoder width={"300px"} height={"24px"} style={{margin:'25px 0px 15px 0px'}}/>
        <div style={{display:'flex', gap:'25px'}}>
          <SkeletonLoder width={"399px"} height={"320px"}/>
          <SkeletonLoder width={"399px"} height={"320px"}/>
          <SkeletonLoder width={"399px"} height={"320px"}/>
        </div>
        <div style={{display:'flex',gap:'25px',marginTop:'25px'}}>
          <div style={{width:'836px'}}>
            <SkeletonLoder width={"100%"} height={"335px"} style={{margin:'0px 0px 25px 0px'}}/>
            <SkeletonLoder width={"200px"} height={"24px"} style={{margin:'0px 0px 15px 0px'}}/>
            <div style={{display:'flex',flexWrap:'wrap',gap:'25px'}}>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
            </div>
            <SkeletonLoder width={"200px"} height={"24px"} style={{margin:'25px 0px 15px 0px'}}/>
            <div style={{display:'flex',flexWrap:'wrap',gap:'25px'}}>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
            </div>
            <SkeletonLoder width={"200px"} height={"24px"} style={{margin:'25px 0px 15px 0px'}}/>
            <div style={{display:'flex',flexWrap:'wrap',gap:'25px',marginBottom:'60px'}}>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
              <SkeletonLoder width={"405px"} height={"320px"}/>
            </div>
          </div>
          <div style={{width:'385px'}}>
            <SkeletonLoder width={"100%"} height={"50px"} style={{margin:'0px 0px 25px 0px'}}/>
            <SkeletonLoder width={"100%"} height={"100px"} style={{margin:'0px 0px 25px 0px'}}/>
            <SkeletonLoder width={"250px"} height={"24px"} style={{margin:'0px 0px 15px 0px'}}/>
            <SkeletonLoder width={"100%"} height={"131px"} style={{margin:'0px 0px 15px 0px'}}/>
            <div>
              {this.state.loderSidetabContentArray.map((item, index) => (
                <SkeletonLoder key={index} width={"100%"} height={"60px"} style={{margin:'0px 0px 5px 0px'}}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    }
    const isArticlePage = history.location.pathname.includes('article') || history.location.pathname.includes('create') || history.location.pathname.includes('fitness');
    const createArticle = (getPermissionStatus("Create article", userPermissions));
    const createCategory = (getPermissionStatus("Create category", userPermissions));
    const updateArticle = (getPermissionStatus("Update article", userPermissions));
    const createOrUpdateQuiz = (getPermissionStatus("Create quiz", userPermissions) || getPermissionStatus("Update quiz", userPermissions) || getPermissionStatus("Delete quiz", userPermissions));
    const createQuiz = getPermissionStatus("Create quiz", userPermissions);
    const updateQuiz = getPermissionStatus("Update quiz", userPermissions);
    const deleteQuiz = (getPermissionStatus("Delete quiz", userPermissions));
    const createRecipe = (getPermissionStatus("Create recipe", userPermissions));
    const updateRecipe = (getPermissionStatus("Update recipe", userPermissions));
    const createVideo = (getPermissionStatus("Create video", userPermissions));
    const editVideo = (getPermissionStatus("Edit video", userPermissions));
    let featuredJourneyData = !_.isNull(featuredJourney)? featuredJourney?.slice(0, 3):[];
    let featuredFitnessData = !_.isNull(featuredVideos)? featuredVideos?.slice(0, 3): [];
    let featuredRecipeData = !_.isNull(recommendedRecipes)? recommendedRecipes?.slice(0, 3):[];
    return (
      <Layout isArticlePage={isArticlePage}>
        <div
          style={{
            display: "flex",
            "max-width": "1246px",
            margin: "auto",
            flexWrap: "wrap",
            justifyContent: "space-between",
            border: "0px solid green",
          }}
        >
          {tab === 1 && history.location.pathname === "/education/adventure"
            ? featuredJourneyData.map((data, index) =>
              index === 0 && data ? (
                <Heading key={index}>{t("Recommended Articles")}</Heading>
              ) : (
                ""
              )
            )
            : ""}
          {tab === 1 &&
          history.location.pathname === "/education/adventure" &&
          featuredJourneyData.length
            ? featuredJourneyData &&
              featuredJourneyData.length > 0 &&
              featuredJourneyData.map((data, index) => (
                <ArticleCard
                  onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                  data={data}
                  key={index}
                  history={history}
                  margin={index === 2 ? "0px" : "25px"}
                  bottom={"0px"}
                  showArticle={this.showArticle}
                />
              ))
            : ""}
          {tab === 2 &&
          history.location.pathname === "/education/videos-library"
            ? featuredFitnessData &&
              featuredFitnessData.length > 0 &&
              featuredFitnessData.map((video, index) =>
                index === 1 && video ? (
                  <Heading key={index}>{t("Recommended Fitness Videos")}</Heading>
                ) : (
                  ""
                )
              )
            : ""}
          {tab === 2 &&
          history.location.pathname === "/education/videos-library" &&
          featuredFitnessData.length
            ? featuredFitnessData &&
              featuredFitnessData.length > 0 &&
              featuredFitnessData.map(
                (video, index) =>
                  index <= 2 && (
                    <RecommendedVideos
                      onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                      video={video}
                      key={index}
                      history={history}
                      grid={0}
                      link={link}
                      margin={index === 2 ? "0px" : "25px"}
                      bottom={"0px"}
                    />
                  )
              )
            : ""}
          {tab === 3 &&
          history.location.pathname === "/education/recipes" &&
          featuredRecipeData.length
            ? (featuredRecipeData &&
              featuredRecipeData.length > 0 &&
              featuredRecipeData.map(
                (nutrition, index) =>{
                  window.console.log("");
                  return index === 2 && nutrition &&(
                    <Heading key={index}>{t("Recommended Recipes")}</Heading>
                  )}
              ))
            : ""}   
          {tab === 3 &&
          history.location.pathname === "/education/recipes" &&
          featuredRecipeData.length
            ? (featuredRecipeData &&
              featuredRecipeData.length > 0 &&
              featuredRecipeData.map(
                (nutrition, index) =>{
                  window.console.log("");
                  return index <= 2 && (
                    <RecommendedRecipe
                      onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                      nutrition={nutrition}
                      key={index}
                      history={history}
                      margin={index === 2 ? "0px" : "0px"}
                      display={index === 0 ? "grid" : "block"}
                    />
                  )}
              ))
            : ""}
        </div>
        <CardWrapper style={{ display: "flex", flexDirection: "column" }}>
          {
            <CustomTabs
              style={{}}
              activeKey={eventKey}
              id="styledTabs"
              onSelect={(e) => this.handleSelect(e)}
              position={
                history.location.pathname === "/education/recipes"
                  ? 1
                  : history.location.pathname === "/education/videos-library"
                    ? 1
                    : history.location.pathname === "/education/adventure"
                      ? 1
                      : 0
              }
            >
              <Tab
                className="educationAdventureTabs adventureTab"
                eventKey={1}
                title={t("Articles")}
              >
                <Switch>
                  <AdminRoute
                    path="/education/adventure/:categoryName/article/:id/edit"
                    component={EditWellnessJourney}
                    adventureCategories={selectedAdventureCategories}
                    themes={themes}
                    themesHeaderAll={themesHeaderAll}
                    parentRoute="/education/adventure"
                    allowedRoles={updateArticle}
                  />
                  <Route
                    path="/education/adventure/:categoryName/article/:id/quiz"
                    render={(props) => <ArticleQuiz {...props} role={role} />}
                  />
                  <Route
                    path="/education/adventure/:categoryName/article/:id/show-all-quiz"
                    render={() => (
                      <ShowAllQuiz
                        history={history}
                        role={role}
                        deleteQuiz={deleteQuiz}
                        createQuiz={createQuiz}
                        updateQuiz={updateQuiz}
                        createOrUpdateQuiz={createOrUpdateQuiz}
                      />
                    )}
                  />
                  <AdminRoute
                    path="/education/adventure/:categoryName/article/:id/add-quiz"
                    component={AddQuizQuestion}
                    parentRoute="/education/adventure"
                    allowedRoles={createOrUpdateQuiz}
                    role={role}
                    history={history}
                  />
                  <AdminRoute
                    path="/education/adventure/:categoryName/article/:id/edit-quiz/:questionId"
                    component={EditQuizQuestion}
                    parentRoute="/education/adventure"
                    allowedRoles={updateQuiz}
                    role={role}
                    history={history}
                  />
                  <Route
                    path="/education/adventure/:categoryName/article/:id"
                    render={() => (
                      <Article
                        updateUserGlobalPoints={updateUserGlobalPoints}
                        role={role}
                        updateArticle={updateArticle}
                        createOrUpdateQuiz={createOrUpdateQuiz}
                        onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                      />
                    )}
                  />
                  <Route
                    path="/education/adventure/:categoryName"
                    render={() => (
                      <Journeys
                        onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                      />
                    )}
                  />
                  <AdminRoute
                    path="/education/create-adventure"
                    component={AddWellnessJourney}
                    adventureCategories={selectedAdventureCategories}
                    parentRoute="/education/adventure"
                    allowedRoles={createArticle}
                    themes={themes}
                    themesHeaderAll={themesHeaderAll}
                  />
                  <AdminRoute
                    path="/education/create-category"
                    component={AddCategory}
                    categoryKey={"ARTICLE"}
                    parentRoute="/education/adventure"
                    allowedRoles={createCategory}
                  />
                  <Route
                    path="/education/adventure"
                    render={() => (
                      <AdventureCategories
                        adventureCategories={adventureCategories}
                        featuredJourney={recommendedArticle}
                        history={history}
                        role={role}
                        createArticle={createArticle}
                        createCategory={createCategory}
                        favoriteArticles={favoriteArticles}
                        onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                      />
                    )}
                  />
                </Switch>
              </Tab>
              <Tab className='adventureTab' eventKey={2} title={t("Fitness")}>
                <div>
                  <Switch>
                    <AdminRoute
                      path="/education/create-video"
                      component={AddFitnessVideo}
                      videoLibraries={selectedvideoLibraries}
                      adventureCategories={adventureCategories}
                      parentRoute="/education/videos-library"
                      allowedRoles={createVideo}
                      themes={themes}
                      themesHeaderAll={themesHeaderAll}
                    />
                    <AdminRoute
                      path="/education/videos-library/edit/:id"
                      component={EditFitnessVideo}
                      videoLibraries={selectedvideoLibraries}
                      adventureCategories={adventureCategories}
                      parentRoute="/education/videos-library"
                      allowedRoles={editVideo}
                      themes={themes}
                      themesHeaderAll={themesHeaderAll}
                      fitnessVideo={fitnessVideo}
                    />
                    <Route
                      path="/education/videos-library/category/:categoryName"
                      render={() => (
                        <VideosLibrary
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                          createVideo={createVideo}
                          allowedRoles={createCategory}
                        />
                      )}
                    />
                    <Route
                      path="/education/videos-library/:id"
                      render={() => (
                        <VideoArticle
                          history={history}
                          updateUserGlobalPoints={updateUserGlobalPoints}
                          featuredVideos={featuredVideos}
                          link="videos-library"
                          allowedRoles={editVideo}
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                          location={location}
                          marginTop="1"
                        />
                      )}
                    />
                    <Route
                      path="/education/videos-library"
                      render={() => (
                        <VideoLibraryDashboard
                          history={history}
                          videoLibrariesDetails={videoLibraries}
                          featuredVideos={featureVideos}
                          link="videos-library"
                          categoryTitle="Education Video Library Categories"
                          role={role}
                          createVideo={createVideo}
                          createCategory={createCategory}
                          favoriteVideos={favoriteVideos[0]}
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                        />
                      )}
                    />
                    <AdminRoute
                      path="/education/create-video-category"
                      component={AddCategory}
                      categoryKey={this.state.categoryKey}
                      parentRoute="/education/videos-library"
                      allowedRoles={createVideo}
                    />
                  </Switch>
                </div>
              </Tab>
              <Tab className='adventureTab' eventKey={3} title={t("Recipes")}>
                <div>
                  <Switch>
                    <AdminRoute
                      path="/education/create-recipe"
                      component={CreateRecipe}
                      parentRoute="/education/recipes"
                      allowedRoles={createRecipe}
                      history={history}
                      themes={themes}
                      themesHeaderAll={themesHeaderAll}
                    />
                    <AdminRoute
                      path="/education/recipes/:id/edit"
                      component={EditRecipe}
                      parentRoute="/education/recipes"
                      allowedRoles={updateRecipe}
                      editRecipeDetails={recipeDetailsById}
                      location={location}
                      history={history}
                      themes={themes}
                      themesHeaderAll={themesHeaderAll}
                      isEdit={1}
                    />
                    <Route
                      path="/education/recipes/category/:categoryName"
                      render={() => (
                        <RecipeCategory
                          history={history}
                          location={location}
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                          createRecipe={createRecipe}
                          createCategory={createCategory}
                        />
                      )}
                    />
                    <Route
                      path="/education/recipes/:id"
                      render={() => (
                        <RecipeDetails
                          history={history}
                          location={location}
                          updateUserGlobalPoints={updateUserGlobalPoints}
                          categoryTitle="recipes Categories"
                          role={role}
                          updateRecipe={updateRecipe}
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                        />
                      )}
                    />
                    <Route
                      path="/education/recipes"
                      render={() => (
                        <Recipes
                          history={history}
                          recommendedRecipes={featuredRecipes}
                          recipeCategories={recipeCategories}
                          categoryTitle="recipes Categories"
                          role={role}
                          createRecipe={createRecipe}
                          createCategory={createCategory}
                          favoriteRecipes={favoriteRecipes}
                          onClickFavoriteRibbon={this.onClickFavoriteRibbon}
                        />
                      )}
                    />
                    <AdminRoute
                      path="/education/create-recipe-category"
                      component={AddCategory}
                      categoryKey={this.state.categoryKey}
                      parentRoute="/education/recipes"
                      allowedRoles={createRecipe}
                    />
                  </Switch>
                </div>
              </Tab>
            </CustomTabs>
          }
        </CardWrapper>
      </Layout>
    );
  }
}

Education.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchAdventureCategories: PropTypes.func.isRequired,
  fetchThemes: PropTypes.func.isRequired,
  fetchSelectedAdventureCategories: PropTypes.func.isRequired,
  adventureCategories: PropTypes.array,
  selectedAdventureCategories: PropTypes.array,
  networkError: PropTypes.string,
  fetchFeaturedJourney: PropTypes.func.isRequired,
  featuredJourney: PropTypes.array,
  videoLibraries: PropTypes.array,
  selectedvideoLibraries: PropTypes.array,
  selectedWebinarVideos: PropTypes.array,
  fetchVideosCategory: PropTypes.func.isRequired,
  fetchSelectedVideosCategory: PropTypes.func.isRequired,
  fetchWebinarVideosCategory: PropTypes.func.isRequired,
  fetchSelectedWebinarVideosCategory: PropTypes.func.isRequired,
  fetchRecommendedVideos: PropTypes.func.isRequired,
  featuredVideos: PropTypes.array,
  updateUserGlobalPoints: PropTypes.func.isRequired,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  webinarLibraries: PropTypes.array,
  getRecipes: PropTypes.func,
  recipeDetailsById: PropTypes.object,
  getRecipeCategories: PropTypes.func,
  fetchSelectedRecipeCategories: PropTypes.func,
  recipeCategories: PropTypes.array,
  getRecommendedRecipeDetails: PropTypes.func,
  recommendedRecipes: PropTypes.array,
  response: PropTypes.object,
  userPermissions: PropTypes.array,
  themes: PropTypes.array,
  dailyTip: PropTypes.object,
  showBiometricPopUp: PropTypes.bool,
  wellnessAssessment: PropTypes.object,
  themesHeader: PropTypes.array,
  companyDetails: PropTypes.object,
  t: PropTypes.func,
  link: PropTypes.string,
  margin: PropTypes.string,
  display: PropTypes.string,
  getFavoriteArticles: PropTypes.func,
  getFavoriteRecipes: PropTypes.func,
  getFavoriteVideos: PropTypes.func,
  likeUnlikeEducationPost: PropTypes.func,
  favoriteArticles: PropTypes.array,
  favoriteVideos: PropTypes.array,
  favoriteRecipes: PropTypes.array,
  fetchFeaturedVideos: PropTypes.func.isRequired,
  featureVideos: PropTypes.array,
  fetchRecommendedArticles: PropTypes.func.isRequired,
  recommendedArticle: PropTypes.array,
  getFeaturedRecipes: PropTypes.func,
  featuredRecipes: PropTypes.array,
  fitnessVideo: PropTypes.object.isRequired,
  themesHeaderAll:PropTypes.array,
};

const mapStateToProps = (state) => ({
  adventureCategories: state.education.adventureCategories,
  themes: state.education.themes,
  selectedAdventureCategories: state.education.selectedAdventureCategories,
  networkError: state.education.networkError,
  featuredJourney: state.education.featuredJourney,
  videoLibraries: state.education.videoLibraries,
  selectedvideoLibraries: state.education.selectedvideoLibraries,
  selectedWebinarVideos: state.education.selectedWebinarVideos,
  featuredVideos: state.education.featuredVideos,
  role: state.auth.role,
  webinarLibraries: state.education.webinarLibraries,
  recipeDetailsById: state.education.recipeDetailsById,
  recipeCategories: state.education.recipeCategories,
  recommendedRecipes: state.education.recommendedRecipes,
  response: state.profileData.response,
  userPermissions: state.profileData.userPermissions,
  dailyTip: state.profileData.dailyTip,
  themesHeader: PropTypes.array,
  wellnessAssessment: state.profileData.wellnessAssessment,
  showBiometricPopUp: state.profileData.showBiometricPopUp,
  companyDetails: state.profileData.userCompany,
  favoriteArticles: state.education.favoriteArticles,
  favoriteVideos: state.education.favoriteVideos,
  favoriteRecipes: state.education.favoriteRecipes,
  featureVideos: state.education.featureVideos,
  recommendedArticle: state.education.recommendedArticle,
  featuredRecipes: state.education.featuredRecipes,
  fitnessVideo: state.education.fitnessVideo,
  themesHeaderAll:state.profileData.themesHeaderAll
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdventureCategories: () => dispatch(getAdventureCategories()),
  fetchThemes: () => dispatch(getThemes()),
  fetchSelectedAdventureCategories: () => dispatch(getSelectedAdventureCategories()),
  fetchFeaturedJourney: () => dispatch(getFeaturedJourney()),
  fetchVideosCategory: () => dispatch(getVideosCategory()),
  fetchSelectedVideosCategory: () => dispatch(getSelectedVideosCategory()),
  fetchWebinarVideosCategory: () => dispatch(getWebinarVideosCategory()),
  fetchSelectedWebinarVideosCategory: () => dispatch(getSelectedWebinarVideosCategory()),
  fetchRecommendedVideos: () => dispatch(getRecommendedVideos()),
  updateUserGlobalPoints: (actionId, activityType, callBackForUpdaingApiDetails) => dispatch(updateUserGlobalPoints(actionId, activityType, callBackForUpdaingApiDetails)),
  fetchUserRole: () => dispatch(getUserRole()),
  getRecipeCategories: () => dispatch(getRecipeCategories()),
  fetchSelectedRecipeCategories: () => dispatch(getSelectedRecipeCategories()),
  getRecommendedRecipeDetails: () => dispatch(getRecommendedRecipeDetails()),
  fetchUserProfile: () => dispatch(getUserProfile()),
  getFavoriteArticles: (id) => dispatch(getFavoriteArticles(id)),
  getFavoriteRecipes: (id) => dispatch(getFavoriteRecipes(id)),
  getFavoriteVideos: (id) => dispatch(getFavoriteVideos(id)),
  likeUnlikeEducationPost: (obj) => dispatch(likeUnlikeEducationPost(obj)),
  fetchFeaturedVideos: () => dispatch(getFeaturedVideos()),
  fetchRecommendedArticles: () => dispatch(getRecommendedArticle()),
  getFeaturedRecipes: () => dispatch(getFeaturedRecipes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Education));
